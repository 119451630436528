import React, {Component} from 'react';
import ibfMain from '../../../images/ibf/ibf-main-color.png';
import wilfred from '../../../images/ibf/wilfred.jpg';
import aws from '../../../images/aws.png';

export default class SuccessStories extends Component {

	render() {
		return (
			<div id="successStories" className="container pt-4 pt-md-5">
				<div className="row pb-4 pb-md-5 justify-content-center">
					<div className="col-lg-8 col-12 text-center">
						<h3 className="we-do-header">
							Success Stories
						</h3>
					</div>
				</div>
				<div className="row pb-4 pb-md-5 mx-auto col-12 col-md-10 col-lg-8">
					<h4 id="iBuyFlowers Reference">Project: Live Inventory</h4>
					<br/>
					<br/>
					<div className="text-center">
						<img src={aws} alt="aws icon" style={{maxWidth: '18%', height: 'auto'}} />
						<img src={ibfMain} alt="ibf banner" style={{maxWidth: '50%', height: 'auto'}} />
					</div>
					<br/>
					<br/>
					<p>iBuyFlowers is a platform that provides flowers directly from the growers and enables their clients to order all kinds of flowers in all kinds of amounts online, which are then delivered (5 days a week, via FedEx) directly to their doorstep, within 4 days.  The company offers more than 4000 flowers and 250 types of pre-assembled bouquets from 40+ growers from Ecuador, Colombia, Costa Rica, United States, and the Netherlands.</p>
					<br/>
					<p>With thousands of different flower offerings that have prices and availability changing every second, iBuyFlowers was facing the need to improve our product availability module to meet the demands that the constant growth of the platform requires. For this Socobox has been providing consulting services helping us analyze, design, implement, and monitor a solution using different AWS services like DynamoDB, SQS, ElastiCache and EKS. </p>
					<br/>
					<p>With this solution, we have been able to scale better and actually improve our cost/benefit relation thanks to their great advice on AWS Cost Management practices</p>
					<br/>
					<div className="media">
						<img src={wilfred} alt="Wilfred de Wit" className="mr-3" style={{maxWidth: '50%', height: 'auto'}} />
						<div className="media-body">
							<p className="mb-0">Wilfred de Wit</p>
							<p className="mb-0">CEO</p>
							<p><a href="http://www.ibuyflowers.com/" style={{color: '#de4c4f'}} >www.iBuyFlowers.com</a>  </p>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
