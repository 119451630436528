import React, {Component} from 'react';
import './App.css';
import Landing from "./Landing/Components/Containers/Landing";


class App extends Component {
  render() {
    return (
      <Landing/>
    );
  }
}

export default App;
